<template>
  <i-modal :title="$t('Select Icon')">
    <row>
      <column auto="auto" v-for="icon in NioIcons" :key="'ni'+icon" class="col-auto px-1 py-0 border mr-2 mb-2 rounded icon_size" v-on:click="selectIcon(icon)">
        <nio-icon :icon="'ni-'+icon"></nio-icon>
      </column>
    </row>
  </i-modal>
</template>

<script>
import IModal from "@core/components/ion-modal/IModal";
import Row from "@core/layouts/row/Row";
import Column from "@core/layouts/col/Col";
import NioIcons from "@/assets/icons"
import {modalController} from "@ionic/vue";
export default {
  components: {Column, Row, IModal},
  emits:['onIconSelect'],
  setup(props, {emit}){
    const selectIcon = async (icon) => {
      emit('onIconSelect', `ni-${icon}`)
      await modalController.dismiss()
    }
    return{
      NioIcons,
      selectIcon,
    }
  }
}
</script>

<style scoped>
.icon_size{
  font-size: 24px;
  color: #526484;
}
.icon_size:hover{
  background: #526484;
  color: #fff;
}
</style>
