<template>
  <page-template>
    <main-block>
      <div>
        <nk-button type="primary" v-if="display.fieldsList" outline v-on:click="$router.push({name: 'adminLeadsForms'})" class="mr-2">
          <nio-icon icon="ni-arrow-left"></nio-icon> {{$t('Back')}}
        </nk-button>
      </div>
      <block-head :title="$t('Leads Forms')+' /' " class="pt-2">
        <template v-slot:title_extras>
          <strong class="text-primary small">{{ formFields.form.form_name || ''}}</strong>
        </template>
        <template v-slot:description_extras></template>
        <template v-slot:default>
          <block-tools>
            <li>
              <nk-button type="primary" v-on:click="prepareFieldForm()">
                <nio-icon icon="ni-plus"></nio-icon> {{$t('New field')}}
              </nk-button>
            </li>
          </block-tools>
        </template>
      </block-head>
      <block-content>
        <template v-if="formMissingCalculatorParams.length">
          <alert :color="formFields.fields.length ? 'danger' : 'info'" pro-style="yes">
            <p v-if="formFields.fields.length">{{$t('The form is missing input fields with the following names, which are required by the mortgage calculator.')}}</p>
            <p v-else>
              {{ $t('The form must contain input fields with the following names or the mortgage calculator will produce incorrect results.') }}
            </p>
            <div v-for="mf in formMissingCalculatorParams" :key="'fmf'+mf.id">
              <span class="fw-500">{{ mf.name }}</span> <span v-if="mf.description">=> {{ mf.description}}</span>
            </div>
          </alert>
        </template>
        <!-- fields list -->
        <card no-padding stretch v-if="display.fieldsList">
          <template v-slot:grouped>
            <card-inner class="p-0">
              <list-table>
                <tb-row head-row>
                  <tb-th break-point="md">{{ $t('Icon')}}</tb-th>
                  <tb-th break-point="md">{{ $t('Type')}}</tb-th>
                  <tb-th>{{ $t('Name')}}</tb-th>
                  <tb-th>{{ $t('Label') }}</tb-th>
                  <tb-th break-point="md"></tb-th>
                  <tb-th class="nk-tb-col-tools"></tb-th>
                </tb-row>
                <tb-row v-if="spinners.formFields">
                  <tb-td :col-span="5" class="text-center">
                    <ion-spinner></ion-spinner>
                  </tb-td>
                </tb-row>
                <template v-if="!spinners.formFields">
                  <tb-row v-for="(f,ndx) in formFields.fields" :key="'f_fld'+f.id">
                    <tb-td break-point="md">
                      <nio-icon v-if="f.icon" :icon="f.icon" class="field_icon"></nio-icon>
                    </tb-td>
                    <tb-td break-point="md" class="text-capitalize">{{f.definition.type}}</tb-td>
                    <tb-td>{{ f.name }}</tb-td>
                    <tb-td>{{ f.label }}</tb-td>
                    <tb-td break-point="md">
                      <span v-if="f.definition.hide_always" class="text-danger">hidden</span>
                      <span v-else-if="f.definition.conditional_display && f.definition.conditional_display.length"
                            class="text-danger">conditional display</span>
                    </tb-td>
                    <tb-td>
                      <ul class="nk-tb-actions gx-1">
                        <template v-if="isDesktop">
                          <table-action icon="ni-pen-fill"
                                        v-on:click="prepareFieldForm(f)"
                                        v-tooltip="'Edit'"></table-action>
                          <table-action icon="ni-trash-fill" v-on:click="deleteField(f,ndx)" v-tooltip="$t('Delete')"></table-action>
                        </template>
                        <li v-else>
                          <drop-down-list>
                            <list-item icon="ni-pen-fill" :text="$t('Edit')" v-on:click="prepareFieldForm(f)"></list-item>
                            <list-item icon="ni-trash-fill" :text="$t('Delete')" v-on:click="deleteField(f,ndx)"></list-item>
                          </drop-down-list>
                        </li>
                      </ul>
                    </tb-td>
                  </tb-row>
                </template>
              </list-table>
            </card-inner>
          </template>
        </card>
        <!-- end of fields list -->

        <!-- field form -->
        <card stretch v-else-if="display.fieldForm">
          <template v-slot:grouped>
            <card-inner>
              <card-header :title="$t(addEditForm.data.id > 0 ? 'Edit field' : 'Add new field')">
                <nk-button type="light" is-icon-only-button v-on:click="switchDisplay('fieldsList')">
                  <nio-icon icon="ni-cross-sm"></nio-icon>
                </nk-button>
              </card-header>
            </card-inner>
            <card-inner>
              <form v-on:submit.prevent="saveField()">
                <form-group>
                  <row>
                    <column md="6" lg="4">
                      <label for="field_name" class="overline-title">Name</label>
                      <b-input type="text" id="field_name" v-model="addEditForm.data.name"></b-input>
                      <form-note>used as <code>name</code> attribute of input field</form-note>
                    </column>
                    <column md="6" lg="4">
                      <label for="field_name" class="d-block overline-title">Icon</label>
                      <nk-button type="light" is-link is-icon-only-button v-on:click="displayIconsPopup=true">
                        <nio-icon :icon="addEditForm.data.icon"></nio-icon>
                      </nk-button>
                    </column>
                  </row>
                </form-group>
                <form-group>
                  <label for="field_label" class="overline-title">Label</label>
                  <b-input type="text" id="field_label" v-model="addEditForm.data.label"></b-input>
                  <form-note>for display to users</form-note>
                </form-group>
                <form-group>
                  <row>
                    <column md="6" lg="3">
                      <label for="field_type" class="overline-title">Type</label>
                      <b-select id="field_type" v-model="addEditForm.data.definition.type">
                        <option v-for="t in validFieldTypes" :value="t" :key="'vft'+t">{{t}}</option>
                      </b-select>
                    </column>
                    <column md="6" lg="4" v-if="['choice', 'multiple'].includes(addEditForm.data.definition.type)">
                      <label class="overline-title">Possible values/Data set</label>
                      <template v-for="(pv, pvi) in addEditForm.data.definition.data_set" :key="'pv'+pvi">
                        <b-input type="text" size="sm" class="mb-1" v-model="addEditForm.data.definition.data_set[pvi]"></b-input>
                      </template>
                      <div class="mt-2">
                        <a href="javascript:;" v-on:click="addEditForm.data.definition.data_set.push('')">Add another</a>
                      </div>
                    </column>
                  </row>
                </form-group>
                <form-group>
                  <row>
                    <column md="12" lg="8" xl="6">
                      <label class="d-block overline-title">Hide</label>
                      <div class="mb-2">
                        <b-check-box id="hide_always" v-model="addEditForm.data.definition.hide_always" label="Always"></b-check-box>
                        <form-note>In case the value is for other purposes and not for display. i.e. for morgage calculation etc.</form-note>
                      </div>
                      <template v-if="!addEditForm.data.definition.hide_always">
                        <div class="text-center pt-4 pb-3">
                          <h6 class="overline-title overline-title-sap"><span>{{$t('OR IF')}}</span></h6>
                        </div>
                        <div v-for="(cd,cdI) in addEditForm.data.definition.conditional_display" :key="'fcd'+cdI"
                             class="mb-2 border-left border-primary">
                          <div class="d-flex align-items-center">
                            <b-select v-if="cdI > 0" v-model="cd.logical_operator" :id="'fcd_l_operator'+cdI" class="rounded-0">
                              <option value="||">{{ $t('OR') }}</option>
                              <option value="&&">{{ $t('AND') }}</option>
                            </b-select>
                            <b-select v-model="cd.field" :id="'fcd_field'+cdI" class="rounded-0">
                              <option value="__self">{{ $t('This field')}}</option>
                              <template v-for="f in formFields.fields" :key="'fcd_field_o'+f.id">
                                <option v-if="f.name !== addEditForm.data.name" :value="f.id">{{f.name}}</option>
                              </template>
                            </b-select>
                            <b-select v-model="cd.operator" :id="'fcd_operator'+cdI" class="rounded-0">
                              <option value="==">=</option>
                              <option value="!=">!=</option>
                              <option value="<">&lt;</option>
                              <option value=">">&gt;</option>
                              <option value="contain">contain</option>
                            </b-select>
                            <b-input type="text" :id="'fcd_val'+cdI" v-model="cd.value" class="rounded-0"></b-input>
                            <a role="button" class="text-danger ml-2" v-on:click="addEditForm.data.definition.conditional_display.splice(cdI,1)">
                              <nio-icon icon="ni-cross"></nio-icon>
                            </a>
                          </div>
                          <div class="bg-light p-3">
                            <div class="d-flex align-items-center" v-for="(cdSub,cdSubI) in cd.sub || 0" :key="'fcd_sub'+cdSubI">
                              <b-select size="sm" v-if="cdSubI < 1" v-model="cdSub.logical_operator" :id="'fcd_sub_l_operator'+cdI" class="rounded-0">
                                <option value="||">{{ $t('OR') }}</option>
                                <option value="&&">{{ $t('AND') }}</option>
                              </b-select>
                              <b-input v-else readonly size="sm" :value="logicalOperations[cd.sub[0].logical_operator]" class="rounded-0"></b-input>
                              <b-select size="sm" v-model="cdSub.field" :id="'fcd_sub_field'+cdI" class="rounded-0">
                                <option value="__self">{{ $t('This field')}}</option>
                                <template v-for="f in formFields.fields" :key="'fcd_field_o'+f.id">
                                  <option v-if="f.name !== addEditForm.data.name" :value="f.id">{{f.name}}</option>
                                </template>
                              </b-select>
                              <b-select size="sm" v-model="cdSub.operator" :id="'fcd_sub_operator'+cdI" class="rounded-0">
                                <option value="==">=</option>
                                <option value="!=">!=</option>
                                <option value="<">&lt;</option>
                                <option value=">">&gt;</option>
                                <option value="contain">contain</option>
                              </b-select>
                              <b-input type="text" size="sm" :id="'fcd_sub_val'+cdI" v-model="cdSub.value" class="rounded-0"></b-input>
                              <a role="button" class="text-danger ml-2" v-on:click="cd.sub.splice(cdSubI,1)">
                                <nio-icon icon="ni-cross"></nio-icon>
                              </a>
                            </div>
                            <div class="mt-2">
                              <a href="javascript:;" v-on:click="!cd.sub ? cd.sub=[{}]:cd.sub.push({})">Add sub</a>
                            </div>
                          </div>
                        </div>
                        <div class="mt-2">
                          <a href="javascript:;" v-on:click="addEditForm.data.definition.conditional_display.push({})">Add condition</a>
                        </div>
                      </template>
                    </column>
                  </row>
                </form-group>
                <form-group v-if="!addEditForm.data.definition.hide_always">
                  <row>
                    <column md="6" lg="6">
                      <label for="field_visibility" class="overline-title mb-1">Visibility</label>
                      <form-note class="mb-2">If none is selected, then it will be visible to everyone. In case you want it not to be visible to anyone, then check the above "Always" checkbox, under the "Hide" section</form-note>
                      <div v-for="r in userRoles" :key="'uroe'+r">
                        <div class="custom-control custom-control-sm custom-checkbox">
                          <input type="checkbox" class="custom-control-input"
                                 :id="'v_role_c'+r"
                                 :value="r" v-model="addEditForm.data.definition.visibility">
                          <label class="custom-control-label text-capitalize" :for="'v_role_c'+r">{{r.replace('is-', '')}}</label>
                        </div>
                      </div>
                    </column>
                  </row>
                </form-group>
                <form-group>
                  <row>
                    <column lg="6">
                      <label class="overline-title">Default value</label>
                      <b-input type="text" id="default_val" v-model="addEditForm.data.definition.default_value"></b-input>
                      <form-note>Provide a default value in case this field is optional for user but required by the system. i.e. required by mortgage calculator</form-note>
                    </column>
                  </row>
                </form-group>
                <form-group class="mt-3">
                  <input type="submit" :value="$t('Save')" class="btn btn-primary mr-2"/>
                  <nk-button type="light" is-link v-on:click="switchDisplay('fieldsList')">{{ $t('Cancel') }}</nk-button>
                </form-group>
              </form>
            </card-inner>
          </template>
        </card>
        <!-- end of field form -->

      </block-content>
    </main-block>
    <ion-modal :is-open="displayIconsPopup"
               @didDismiss="displayIconsPopup=false"
               :css-class="isDesktop ? 'modal-web': ''">
      <nio-icons-modal @on-icon-select="setFieldIcon"></nio-icons-modal>
    </ion-modal>
  </page-template>
</template>

<script>
import {
  alertController,
  IonModal,
  IonSpinner,
  isPlatform, loadingController,
  onIonViewWillEnter, useIonRouter
} from '@ionic/vue';
import {computed, defineComponent, reactive, ref, watch} from 'vue';
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import "bootstrap"
import BlockHead from "@core/layouts/main-block/components/BlockHead";
import axios from "@/libs/axios";
import Card from "@core/components/cards/Card";
import CardInner from "@core/components/cards/components/CardInner";
import ListTable from "@core/components/list-table/ListTable";
import TbRow from "@core/components/data-tables/components/TbRow";
import TbTh from "@core/components/data-tables/components/TbTh";
import TbTd from "@core/components/data-tables/components/TbTd";
import TableAction from "@core/components/special-table/components/TableAction";
import DropDownList from "@core/components/dropdown-list/DropDownList";
import ListItem from "@core/components/dropdown-list/ListItem";
import {useRoute} from "vue-router";
import CardHeader from "@core/components/cards/CardHeader";
import FormGroup from "@core/layouts/form-group/FormGroup";
import Row from "@core/layouts/row/Row";
import Column from "@core/layouts/col/Col";
import BInput from "@core/components/bp-form/components/BInput";
import FormNote from "@core/components/bp-form/components/FormNote";
import BSelect from "@core/components/bp-form/components/BSelect";
import useCommonFunc from "@core/comp-functions/common"
import {useI18n} from "vue-i18n";
import useIonComponents from "@core/IonComponents"
import NioIconsModal from "@core/components/nio-icon/NioIconsModal";
import NkButton from "@core/components/button/NkButton";
import Alert from "@core/components/alert/Alert";
import BCheckBox from "@core/components/bp-form/components/BCheckBox";
import PageTemplate from "@core/layouts/page/PageTemplate";
import BlockTools from "@core/layouts/main-block/components/BlockTools";

export default defineComponent({
  components: {
    BlockTools,
    PageTemplate,
    BCheckBox,
    Alert,
    NkButton,
    NioIconsModal,
    BSelect,
    FormNote,
    BInput,
    Column,
    Row,
    FormGroup,
    CardHeader,
    IonModal,
    IonSpinner,
    ListItem,
    DropDownList,
    TableAction,
    TbTd,
    TbTh,
    TbRow,
    ListTable,
    CardInner,
    Card,
    BlockHead,
    BlockContent,
    MainBlock,
  },
  setup() {

    let display = reactive({
      fieldsList: true,
      fieldForm: false,
    })
    const ionRouter = useIonRouter()
    const route     = useRoute()
    const isDesktop = isPlatform('desktop')
    let formFields  = reactive({
      form: {
        mortgage_calculator:{
          params:[],
        }
      },
      fields: [],
    })
    let addEditForm = reactive({
      data: {
        id: 0,
        icon: 'ni-plus-sm',
        name: '',
        label: '',
        definition: {
          type: 'text',
          visibility: [],
          conditional_display: [],
        }
      },
    })
    const validFieldTypes = ['text', 'number', 'choice', 'multiple']
    const{userRoles} = useCommonFunc()
    const{t} = useI18n()
    const{IonToast} = useIonComponents()
    let displayIconsPopup = ref(false)
    let spinners = reactive({
      formFields: false,
    })

    const getFormFields = () => {
      //formFields.value.splice(0)
      spinners.formFields = true
      axios.get(`/api/admin/leads/forms/${route.params.id}/fields`)
          .then(r=>{
            formFields.form = r.data.data.form
            formFields.fields = r.data.data.fields
            /*for(let x in r.data.data){
              formFields.value.push(r.data.data[x])
            }*/
          })
          .catch(er=>{
            console.log(er)
          })
          .then(()=> spinners.formFields = false)
    }

    const switchDisplay = (to) => {
      for(let x in display){
        display[x] = to === x;
      }
    }
    const prepareFieldForm = (d) => {
      addEditForm.data.id = d ? d.id : 0
      addEditForm.data.icon = d ? d.icon : 'ni-plus-sm'
      addEditForm.data.name = d ? d.name : ''
      addEditForm.data.label = d ? d.label : ''
      addEditForm.data.definition = d ? d.definition : {visibility: [], conditional_display: []}
      if(!addEditForm.data.definition.conditional_display){
        addEditForm.data.definition.conditional_display = []
      }
      switchDisplay('fieldForm')
    }

    const saveField = async () => {
      let l = await loadingController.create({message: t('Please wait...')})
      await l.present()
      let data = Object.assign({}, addEditForm.data)
      data.form_id = route.params.id
      data.definition = JSON.stringify(data.definition)
      axios.put('/api/admin/leads/forms/field', data)
          .then(r=>{
            if(addEditForm.data.id > 0){
              for(let x in formFields.fields){
                if(parseInt(formFields.fields[x].id) === parseInt(addEditForm.data.id)){
                  formFields.fields[x] = r.data.data
                  break;
                }
              }
            }
            else{
              formFields.fields.push(r.data.data)
            }
            switchDisplay('fieldsList')
          })
          .catch(er=>{
            console.log(er)
            let msg = t('errors.general_error')
            msg = er.response ? (er.response.data.message || er.response.status+' '+er.response.status_text) : msg
            IonToast({message: msg, position: 'top', duration: 3500, color: 'danger'})
          })
          .then(()=> l.dismiss())
    }
    const deleteField = (field,index) => {
      alertController.create({
        cssClass: isDesktop ? 'alert-web' : '',
        header: t('Delete field'),
        message: t('Are you sure you want to delete this field?'),
        buttons: [
          {
            text: t('Cancel'),
            cssClass: isDesktop ? 'alert-btn-light mr-1' : '',
          },
          {
            cssClass: isDesktop ? 'alert-btn-danger' : '',
            text: t('Delete'), handler: async () => {
              const l = await loadingController.create({message: t('Please wait...')})
              await l.present()
              axios.delete(`/api/admin/leads/forms/field/${field.id}`)
                  .then( () => {
                    formFields.fields.splice(index,1)
                    IonToast({message: t('Thank you! action completed'), position: 'top', color:'primary', duration: 3000})
                  })
                  .catch(er => {
                    let m = t('errors.general_error')
                    m = er.response ? (er.response.data.message || er.response.status+' '+er.response.statusText) : m
                    IonToast({message: m, position: 'top', color:'danger', duration: 3500})
                  })
                  .then( () => l.dismiss())
            }
          },
        ]
      }).then((iA) => iA.present())
    }

    const setFieldIcon = (icon) => {
      addEditForm.data.icon = icon
    }

    const formMissingCalculatorParams = computed(()=>{
      let missing = []
      if(!formFields.form.mortgage_calculator || !formFields.form.mortgage_calculator.params){
        return []
      }
      for(let x  in formFields.form.mortgage_calculator.params){
        const tmp = formFields.form.mortgage_calculator.params[x]
        if (!formFields.fields.some(e => e.name === tmp.name)) {
          missing.push(tmp)
        }
      }
      return missing
    })

    onIonViewWillEnter(()=>{
      getFormFields()
    })

    watch(addEditForm.data, (n)=>{
      switch (n.definition.type){
        case 'multiple':
        case 'choice':
          !addEditForm.data.definition.data_set ? (addEditForm.data.definition.data_set = ['']) : null
          break;
        default:
          delete addEditForm.data.definition.data_set
          break;
      }
    })

    return {
      deleteField,
      display,
      displayIconsPopup,
      formFields,
      formMissingCalculatorParams,
      ionRouter,
      logicalOperations: {'&&': 'AND', '||': 'OR'},
      prepareFieldForm,
      saveField,
      setFieldIcon,
      spinners,
      switchDisplay,
      userRoles,
      validFieldTypes,

      addEditForm,
      isDesktop,
    }
  }
});

</script>

<style scoped>
.field_icon{
  font-size: 24px;
}
</style>
